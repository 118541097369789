<template>
  <div class='container'>
    <div class="content">
      <h2>请选择学生过往病史</h2>
      <div class="options">
        <div :class="['option',selectArr.indexOf(String(index))!==-1?'active':'']" v-for="(item,index) in options" :key="item" @click="optionChange(index)">
          {{ item }}
        </div>
      </div>
      <h2>其他病史</h2>
      <textarea class="area" v-model="formData.past_medical_history_other" placeholder="请输入其他过往病史"></textarea>
    </div>
    <div class="bottom">
      <button @click="submit">保存</button>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {profileUpdateV2} from '@/api/student.js'
export default {
  data(){
    return{
      isShow:false,
      options:['无','脑炎','癫痫','心脏病','哮喘','过敏史','脑结核','小儿麻痹'],
      selectArr:[],
      formData: {
          id: '',
          sn: '', // 学籍号
          grade: '', // 年级
          studyClass: '', // 班级
          gender: '', // 性别
          area: '', // 校区
          birthday: '', // 出生日期
          nation: '', // 民族
          birth_place: '', // 籍贯
          situation: '', // 学习情况
          interest: [], // 兴趣
          interest_other: '', // 兴趣
          home_address: '', // 家庭地址
          health_status: '', // 健康状况
          psychological_diagnsis: '', // 心理诊断记录
          past_medical_history: [], // 过往身体病史
          past_medical_history_other: '', // 过往身体病史
          parent_relationship: '', // 父母关系
          family_atmosphere: '', // 家庭气氛
          family_economy: '', // 家庭经济情况
          other: '', // 其他情况
          f_name: '', // 父亲名字
          f_age: '', // 年龄
          f_occupation: '', // 职业
          f_characteristics: '', // 个人特点
          f_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          m_name: '', // 母亲名字
          m_age: '', // 年龄
          m_occupation: '', // 职业
          m_characteristics: '', // 个人特点
          m_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          study: [], // 学习经历
          event: [], // 重大生活事件
          home: [], // 家庭成员
          province: '', // 省
          city: '', // 市
          county: '' // 区
      },
    }
  },
  computed:{
    ...mapState(['student']),
  },
  async created(){
    await this.getDetail()
  },
  methods:{
    async getDetail(){
      const {id}=this.$route.query
      await this.$store.dispatch('getStudentDetail',id)
      this.formData=this.student
      this.selectArr=this.formData.past_medical_history
    },
    optionChange(index){
      index=String(index)
      console.log(this.selectArr.indexOf(index));
      if(this.selectArr.indexOf(index)===-1){
        if(index==0)this.selectArr=[]
        if(index!==0)this.selectArr=this.selectArr.filter(item=>item!=0)
        this.selectArr.push(index)
      }else{
        this.selectArr=this.selectArr.filter(item=>item!==index)
      }

    },
    onConfirm(val){
      console.log(val);
      this.formData.province=val[0].name
      this.formData.city=val[1].name
      this.formData.county=val[2].name
      this.isShow=false
    },
    async submit(){
      if(this.selectArr.length<=0){
        this.$toast.fail('请选择过往病史')
        return
      }
      this.formData.past_medical_history=this.selectArr
      const res = await profileUpdateV2(this.formData)
      this.$toast.success('修改成功')
      this.$router.back()
    },
  }
}
</script>
<style lang='less' scoped>
.container{
  min-height: 100%;
  padding-top: 5.3333vw;
  padding-bottom: 18.6667vw;
  .content{
    padding: 0 4vw;
    h2{
      margin-bottom: 4vw;
    }
    .options{
      margin-bottom: 1.3333vw;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .option{
        margin-right: 3%;
        margin-bottom: 2.6667vw;
        width: 22%;
        height: 10.6667vw;
        text-align: center;
        line-height: 10.6667vw;
        background: #F5F7F9;
        border-radius: 1.3333vw;
        &:nth-of-type(4n){
          margin-right: 0;
        }
      }
      .option.active{
        color: #fff;
        background-color: #5EDBC9;
      }
    }
    textarea{
      padding: 4vw;
      width: 100%;
      min-height: 53.3333vw;
      background: #F5F7F9;
      border-radius: 1.3333vw;
      font-weight: 550;
    }
  }
  .bottom{
    position: fixed;
    display: flex;
    align-items: center;
    padding: 0 4vw;
    bottom: 0;
    width: 100%;
    height: 16vw;
    button{
      width: 100%;
      color: #fff;
      height:13.8667vw;
      line-height: 13.8667vw;
      font-size: 4vw;
      border-radius: 13.3333vw;
      background: #5EDBC9;
    }
  }
}
</style>